import { STACK_KEY_NAME } from "../constants/const";

export const byteToGigaByte = (size) => {
  return (size / Math.pow(10, 9)).toFixed(1);
};

export const getPercentage = (current, overall) => {
  return Math.floor((current / overall) * 100);
};

export const showAtMaxChar = (value, limit) => {
  return value?.slice(0, limit) + (limit >= value?.length ? "" : "...");
};

export const convertGoogleDriveUrlToDirectLink = (publicUrl) => {
  const fileIdMatch = publicUrl.match(/\/d\/([a-zA-Z0-9_-]+)/);
  if (!fileIdMatch || fileIdMatch.length < 2) {
    throw new Error("Invalid Google Drive URL");
  }

  const fileId = fileIdMatch[1];
  const directLink = `https://drive.google.com/uc?export=download&id=${fileId}`;

  return directLink;
};

export const getElapsedTimeInMinutes = (timestamp) => {
  const inputTime = new Date(timestamp);

  const currentTime = new Date();

  const differenceInMilliseconds = currentTime - inputTime;

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return Math.floor(differenceInMinutes);
};

export const fakDelay = (time) =>
  new Promise((res, rej) => setTimeout(() => res(), time));

export const mergeData = (setState, res, isResetData) => {
  if (isResetData) {
    setState({
      data: res?.data?.data,
      limit: res?.data?.limit,
      page: res?.data?.page,
      total: res?.data?.total,
    });
  } else {
    setState((prev) => {
      return {
        data: [...(prev?.data || []), ...(res?.data?.data || [])],
        limit: res?.data?.limit,
        page: res?.data?.page,
        total: res?.data?.total,
      };
    });
  }
};

export const logoutUser = () => {
  localStorage?.removeItem("token");
  localStorage?.removeItem(STACK_KEY_NAME);
  window?.location?.reload();
};
