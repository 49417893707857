import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useRenameFolderApi = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const renameFolder = async ({
    folderId,
    newFolderName,
    onSuccessCallback,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.patch(
        `/drive/rename-folder/${folderId}`,
        {
          newFolderName,
        }
      );
      if (res?.status === 200) {
        setData(res?.data?.data);
        onSuccessCallback?.();
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      setError(err?.message);
      console.log({ err })
      onErrorCallback?.(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, renameFolder };
};

export default useRenameFolderApi;
