import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Spinner from "../Assets/images/loading.gif";

const DeleteFolder = ({ onCancel, onDelete, areBtnsDisabled, isFile }) => {
  const loadingRef = useRef(false);

  const submitForm = (event) => {
    event?.preventDefault();
    if (event?.key === "Enter" && !loadingRef?.current) {
      onDelete();
    }
  };

  useEffect(() => {
    loadingRef.current = areBtnsDisabled;
  }, [areBtnsDisabled]);

  useEffect(() => {
    document?.addEventListener("keypress", submitForm);

    return () => {
      document?.removeEventListener("keypress", submitForm);
    };
  }, []);

  return (
    <form className="overlay shadow-[4_10px_25px_0px_rgba(#00000005)]">
      <div className="popup w-[595px]">
        <div>
          <h2 className="text-[#101828] text-[18px] font-[600] leading-[24px]">
            Delete {isFile ? "File" : "Folder"}
          </h2>
        </div>
        <div className="border-b-[1px] border-[#0000001F] pt-[24px]"></div>
        <div className="pt-[24px]">
          <h2 className="text-[18px] font-[500] text-[#101828] leading-[26px]">
            Are You Sure That You Want To Delete This{" "}
            {isFile ? "File" : "Folder"}?
          </h2>
        </div>
        <div className="w-[100%] flex justify-end pt-[24px] gap-[16px]">
          <button
            type="button"
            disabled={areBtnsDisabled}
            onClick={onCancel}
            className="border border-[#D0D5DD] py-[8px] px-[24px] rounded-[8px] cursor-pointer md:text-[16px] text-[14px] font-[500] text-[#101828]"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={areBtnsDisabled}
            onClick={(e) => {
              e?.preventDefault();
              onDelete();
            }}
            className="bg-[#D92D20] rounded-[8px] py-[8px] px-[24px] text-[white] font-[500] md:text-[16px] text-[14px] cursor-pointer"
          >
            {areBtnsDisabled ? (
              <div className="px-[12px]">
                <img src={Spinner} alt="Spinner-gif" className="w-[24px]" />
              </div>
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

DeleteFolder.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  areBtnsDisabled: PropTypes.bool,
  isFile: PropTypes.bool,
};

export default DeleteFolder;
