import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useUpdateFileInfo = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const onUpdateFileInfo = async ({
    fileId,
    data,
    onSuccessCallback,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.patch(`drive/db/file/${fileId}`, data);
      if (res?.status === 200) {
        setData(res?.data?.data);
        onSuccessCallback?.();
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      console.log({ err });
      onErrorCallback?.(err?.response?.data?.message || err?.message);
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, onUpdateFileInfo };
};

export default useUpdateFileInfo;
