import React from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import { BackArrow } from "../Assets/Iconset";

const AudioPlayer = ({ id, name = "Audio.mp3", onBack }) => {
  const audioLink = `https://drive.google.com/file/d/${id}/preview`;

  return ReactDom.createPortal(
    <div className="audio-player-container absolute bottom-[0px] rigth-[0px] left-[0px] w-full flex justify-end flex-col h-[88%] md:h-[90%] bg-black">
      <div className="relative w-full flex flex-col h-[100%]">
        <div className="bg-[#242424] w-full absolute flex justify-center items-center h-[10%] top-[0px] right-[0px] left-[0px]">
          <div className="container mx-auto absolute flex justify-start items-center">
            <button
              onClick={onBack}
              className="flex items-center cursor-pointer gap-[12px]"
            >
              <BackArrow className="cursor-pointer" />
              <p className="text-white text-[18px] font-[600]">{name}</p>
            </button>
          </div>
        </div>

        <iframe
          src={audioLink}
          width="640"
          height="80"
          title="audio-player"
          allow="autoplay"
          className="w-full h-[100%]"
        ></iframe>
      </div>
    </div>,
    document?.getElementById("audio-player")
  );
};

AudioPlayer.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

export default AudioPlayer;
