import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import CancelBtn from "../components/CancelBtn";
import useUploadFile from "../hooks/useUploadFile";
import uploadFileImage from "../Assets/images/uploadfile.svg";
import { BorderDash, Close, ErrorBorderDash } from "../Assets/Iconset";
import { MAX_FILE_SIZE, VALID_FILE_TYPES } from "../constants/const";
import { FolderContext } from "../context/folder/folder";
import Spinner from "../Assets/images/loading.gif";

const Upload = ({ onCancel, loadInitialData }) => {
  const submitButtonRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const { uploadFile, isLoading } = useUploadFile();
  const [isUnsupportedFile, setIsUnsupportedFile] = useState(false);

  const {
    folderData: { stack },
  } = useContext(FolderContext);

  const handleFileSelect = (event) => {
    const fileInput = event.target;
    const file = event?.target?.files?.[0];
    submitButtonRef?.current?.focus();

    if (!file) {
      return;
    }

    if (!VALID_FILE_TYPES?.includes(file?.type)) {
      setIsUnsupportedFile(true);
      setSelectedFile(null);
      toast.error(
        "File must be one of audio/Mp3 ,audio/M4a, and audio/x-.Wav type only",
        {
          hideProgressBar: true,
          className: "error-toast",
        }
      );
      fileInput.value = '';
      return;
    }

    if (file?.size > MAX_FILE_SIZE) {
      setSelectedFile(null);
      toast?.error("File must be at max of 500 MB only", {
        hideProgressBar: true,
        className: "error-toast",
      });
      fileInput.value = '';
      return;
    }

    setSelectedFile(file);
    setIsUnsupportedFile(false);
    toast?.success("File is selected successfully", {
      hideProgressBar: true,
      className: "success-toast",
    });
  };
  const handleCancle = () => {
    setIsUnsupportedFile(false);
    onCancel?.();
  };
  const handleFileUpload = (e) => {
    e?.preventDefault();
    uploadFile({
      file: selectedFile,
      parentFolderId: stack?.at(-1)?.id,
      on1stSuccessCallback: () => {
        onCancel?.();
        loadInitialData?.();
      },
      onErrorCallback: (error) => {
        loadInitialData?.();
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  useEffect(() => {
    if (submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [selectedFile]);

  return (
    <div className="overlay">
      <form className="popup w-[594px]">
        <div className="w-[100%] flex justify-between items-center">
          <p className="text-[18px] font-[600] text-[#101828]">Upload File</p>
          <button onClick={onCancel}>
            <Close />
          </button>
        </div>
        <div className="border-b-[1px] border-[#0000001F] pt-[24px]"></div>
        <div className={`relative mt-[24px] p-2 rounded-[6px] min-h-[214px] md:border-none border-dashed border border-[#D0D5DD] ${!!isUnsupportedFile && "border-red-500 bg-[#D92D201A]"}`}>
          <span className="absolute top-0 bottom-0 left-0 right-0 z-10 md:block hidden ">
            <BorderDash />
          </span>
          <input
            accept=".mp3, .m4a, .wav"
            disabled={isLoading}
            onChange={handleFileSelect}
            type="file"
            className="absolute top-0 bottom-0 left-0 right-0 z-30 h-full opacity-0 cursor-pointer"
          />
          <div
            className={`absolute top-0 right-0 bottom-0 left-0 z-20  flex flex-col items-center justify-center ${selectedFile ? "bg-[#155EEF0D]" : ""
              } ${true ? "cursor-default" : ""}`}
          >
            {!selectedFile && !isUnsupportedFile && (
              <div className="flex flex-col gap-[12px] items-center justify-center">
                <img
                  src={uploadFileImage}
                  alt=""
                  className="w-[52px] h-[52px]"
                />
                <p className="text-[16px] font-[500] text-[#344054]">
                  Click on{" "}
                  <span className="text-[#155EEF] underline underline-offset-2">
                    Choose File
                  </span>
                </p>
              </div>
            )}
            {!!isUnsupportedFile && (
              <>
                <span className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-red md:block hidden">
                  <ErrorBorderDash />
                </span>
                <div className="flex items-center justify-center text-[center] flex-col gap-[8px] pt-[12px]">
                  <img
                    src={uploadFileImage}
                    alt=""
                    className="w-[52px] h-[52px]"
                  />
                  <p className="text-[#344054] font-[500] text-center">
                    {" "}
                    Upload Failed. File Format Not Suppported
                  </p>
                  <p className="text-[16px] font-[500] text-[#344054]">
                    Please{" "}
                    <span className="text-[#155EEF] underline underline-offset-2">
                      Try again
                    </span>
                  </p>
                </div>
              </>
            )}
            {!!selectedFile && (
              <div className="flex items-center justify-center text-[center] flex-col gap-[8px] pt-[12px] w-full">
                <img
                  src={uploadFileImage}
                  alt=""
                  className="w-[52px] h-[52px]"
                />
                <p className="text-[#344054] font-[500] p-3 overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[100%]">
                  {selectedFile?.name}
                </p>
                <p className="text-[#155EEF] text-[16px] font-[500] underline underline-offset-2 ">
                  Replace File
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="pt-[12px] flex justify-between flex-wrap">
          <p className="text-[14px] font-[500] text-[#98A2B3]">
            Supported Formats: Mp3, M4a, .Wav
          </p>
          <p className="text-[14px] font-[500] text-[#98A2B3]">
            Maximum Size: 500MB
          </p>
        </div>
        <div className="pt-[32px] flex justify-end items-center w-[100%] gap-[16px]">
          <CancelBtn
            disabled={isLoading}
            onClick={handleCancle}
            className="w-[99px] cursor-pointer"
          >
            Cancel
          </CancelBtn>
          <button
            ref={submitButtonRef}
            type="submit"
            disabled={!selectedFile}
            onClick={handleFileUpload}
            className="text-[white] md:text-[16px] text-[14px] bg-[#155EEF] w-[99px] h-[40px] rounded-[8px] font-[500] cursor-pointer border border-[#155EEF] disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <div className="px-[12px] flex justify-center items-center">
                <img src={Spinner} alt="Spinner-gif" className="w-[24px]" />
              </div>
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

Upload.propTypes = {
  onCancel: PropTypes.func,
  loadInitialData: PropTypes.func,
};

export default Upload;
