import React from "react";

import Button from "../components/Button";

import useLogin from "../hooks/useLogin";
import Spinner from "../Assets/images/loading.gif";
import GoogleLogo from "../Assets/images/google-logo.svg";
import logo from "../Assets/images/voisirra.png";

const Authentication = () => {
  const { login, isLoading } = useLogin();

  return (
    <div className="bg h-[100vh] flex justify-center items-center">
      <div className="max-w-[494px] m-full rounded-[12px] bg-[white] border border-1-red m-auto md:p-[32px] p-[24px] mx-[30px]">
        <div className="flex flex-col items-center justify-center">
          <img src={logo} alt="" className="w-[133px] h-[24px]" />
          <h2 className="text-[#101828] text-[24px] font-[600] pt-[32px]  leading-[24px]">
            Welcome to Voisirra
          </h2>
          <p className="text-[16px] text-[#98A2B3] pt-[12px] font-[500] leading-[24px]">
            Clear Sound, No Background
          </p>
          <p className="text-[#101828B2] text-[16px] font-[400] pt-[24px] text-center">
            Introducing "Voisirra," your ultimate audio enhancement solution.
            With Voisirra, you can effortlessly upload your audio files and
            watch as it works its magic to remove pesky background noise,
            leaving you with crisp, clear sound.
          </p>
        </div>
        <div className="md:pt-[60px] pt-[40px]">
          <Button
            disabled={isLoading}
            img={GoogleLogo}
            onClick={() => login()}
            isCircular
            className="min-h-[40px] !py-[0px]"
          >
            {isLoading ? (
              <img src={Spinner} alt="spinner-gif" className="w-[30px]" />
            ) : (
              "Sign in With Google"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
