import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useShareLink = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const onCreateShareLink = async ({ fileId, onSuccessCallback , onErrorCallback}) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.post(`drive//share-folder/${fileId}`);
      if (res?.status === 200) {
        setData(res?.data?.publicLink);
        onSuccessCallback?.(res?.data?.publicLink);
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      setError(err?.message);
      onErrorCallback?.(err?.response?.data?.message || err?.message)
    } finally {
      setIsLoading(false);
    }
  };

  return { onCreateShareLink, isLoading, data, error };
};

export default useShareLink;
