import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { fakDelay, logoutUser } from "../utils/util";

async function refreshToken() {
  try {
    const jwtToken = localStorage.getItem("token");
    const decodedData = jwtDecode(jwtToken);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const res = await axiosInstance?.post(`${BASE_URL}/users/refresh-token`, {
      refreshToken: decodedData?.refreshToken,
    });

    return res?.data?.newJwtToken;
  } catch (err) {
    console.log({ err });
  }
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((request) => {
  const jwtToken = localStorage?.getItem("token");
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    localStorage.removeItem("token");
    window.location.reload();
    return;
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.data?.isLogoutUser) {
      logoutUser();
      return;
    }

    if (error?.response?.data?.isRootFolderDelete) {
      toast.error(
        "Logging out the user, since root folder got deleted by someone.",
        {
          hideProgressBar: true,
          className: "error-toast",
        }
      );
      await fakDelay(3000);
      logoutUser();
      return;
    }
    if (error?.response && error?.response?.status === 401) {
      try {
        const newToken = await refreshToken();

        const originalReuqest = error?.config;
        originalReuqest.headers["Authorization"] = `Bearer ${newToken}`;

        localStorage.setItem("token", newToken);

        return axios(originalReuqest);
      } catch (err) {
        return Promise?.reject(err);
      }
    }
    return Promise?.reject(error);
  }
);
