import React, { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import DeleteFolder from "../popups/DeleteFolder";
import MenuDropdown from "../dropdownmenus/MenuDropdown";
import RenamePopup from "../popups/RenamePopup";
import ShareFolder from "../popups/ShareFolder";

import Dotmenu from "../Assets/images/dotmenu.svg";
import FolderIcon from "../Assets/images/folder.png";

import useDeleteFolderApi from "../hooks/useDeleteFolderApi";
import useRenameFolderApi from "../hooks/useRenameFolderApi";
import useOutsideClick from "../hooks/useOutsideClick";
import useShareLink from "../hooks/useShareLink";

const FolderCreated = ({ name, onClick, folderId, loadInitialData }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRenameModelOpen, setIsRenameModelOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareableLinkModalOpen, setIsShareableLinkModalOpen] =
    useState(false);

  const [newFolderName, setNewFolderName] = useState(name || "");

  const { renameFolder, isLoading: isRenamingFolder } = useRenameFolderApi();
  const { deleteFolder, isLoading: isDeletingFolder } = useDeleteFolderApi();
  const { onCreateShareLink, data: shareableLink } = useShareLink();
  const { ref, shouldNotConsiderRef } = useOutsideClick({
    callback: () => setIsMenuOpen(false),
  });

  const onRenameFolder = () => {
    renameFolder({
      folderId,
      newFolderName,
      onSuccessCallback: () => {
        setIsRenameModelOpen(false);
        loadInitialData?.();
        toast.success("Folder renamed successfully", {
          hideProgressBar: true,
          className: "success-toast",
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };
  const onDeleteFolder = () => {
    deleteFolder({
      folderId,
      onSuccessCallback: () => {
        setIsDeleteModalOpen(false);
        loadInitialData?.();
        toast.dark("Folder deleted successfully", {
          hideProgressBar: true,
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };
  const onShareFolder = () => {
    onCreateShareLink({
      fileId: folderId,
      onSuccessCallback: () => {
        setIsShareableLinkModalOpen(true);
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  const onOpenModalAndCloseMenu = (type) => {
    if (type === "rename") {
      setIsRenameModelOpen(true);
    } else if (type === "delete") {
      setIsDeleteModalOpen(true);
    } else {
      onShareFolder();
    }
    setIsMenuOpen(false);
  };

  const onCancelRename = () => {
    setIsRenameModelOpen(false);
    setNewFolderName(name);
  };

  const onSharePublicLink = () => {
    navigator.clipboard.writeText(shareableLink);
    toast.success("Link copied successfully", {
      hideProgressBar: true,
      className: "success-toast",
    });
    setIsShareableLinkModalOpen(false);
  };

  return (
    <div className="relative w-full max-w-[384px] lg:h-[74px] h-[90px] bg-[white] p-[16px] rounded-[12px] flex gap-[12px] items-center  justify-between shadow-[#00000005] cursor-pointer">
      {isMenuOpen && (
        <div
          ref={ref}
          className="absolute right-[20px] top-[50px] z-10 shadow-[0_14px_16px_-4px_(#10182814)]"
        >
          <MenuDropdown
            onRename={() => onOpenModalAndCloseMenu("rename")}
            onDelete={() => onOpenModalAndCloseMenu("delete")}
            onShare={() => onOpenModalAndCloseMenu("share")}
          />
        </div>
      )}
      {isRenameModelOpen && (
        <RenamePopup
          value={newFolderName}
          onNameChange={(e) => setNewFolderName(e?.target?.value)}
          onSubmit={onRenameFolder}
          onCancel={onCancelRename}
          areBtnsDisabled={isRenamingFolder}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteFolder
          areBtnsDisabled={isDeletingFolder}
          onCancel={() => setIsDeleteModalOpen(false)}
          onDelete={onDeleteFolder}
        />
      )}
      {isShareableLinkModalOpen && (
        <ShareFolder
          onCancel={() => setIsShareableLinkModalOpen(false)}
          onShare={onSharePublicLink}
          link={shareableLink}
        />
      )}
      <button
        className="flex md:flex-row flex-col md:gap-[16px] gap-[8px] max-w-[90%] md:items-center items-start flex-1
        "
        {...{ onClick }}
      >
        <img
          src={FolderIcon}
          alt=""
          className="w-[53px] h-[42px] cursor-pointer"
        />
        <p className="overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[100%] font-[600]">
          {name}
        </p>
      </button>

      <button
        onClick={() => setIsMenuOpen((prev) => !prev)}
        ref={shouldNotConsiderRef}
      >
        <img
          src={Dotmenu}
          alt="dotMenuImage"
          className="w-[24px] h-[24px] relative cursor-pointer"
        />
      </button>
    </div>
  );
};

FolderCreated.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  folderId: PropTypes.string,
  loadInitialData: PropTypes.func,
};

export default FolderCreated;
