import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";
import { LIMIT } from "../constants/const";
import { mergeData } from "../utils/util";

const useGetFolderDataApi = () => {
  const [data, setData] = useState({
    data: [],
    total: 0,
    page: 1,
    limit: LIMIT,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const getFolderData = async ({
    isResetData = true,
    folderId,
    onSuccessFullCallback,
    page = 1,
    limit = LIMIT,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const paginationQuery =
        page && limit ? `?page=${page}&limit=${limit}` : "";
      const res = await axiosInstance.get(
        `/drive/list-files/${folderId}${paginationQuery}`
      );

      if (res?.status === 200) {
        mergeData(setData, res, isResetData);
        onSuccessFullCallback?.();
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
      onErrorCallback?.(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, getFolderData };
};

export default useGetFolderDataApi;
