import { useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useGetStorageApi = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getStorage = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get(`drive/storage`);
      if (res?.status === 200) {
        setData(res?.data?.data);
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      setError(err?.message);
      toast?.error(err?.message, {
        hideProgressBar: true,
        className: "error-toast",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, getStorage };
};

export default useGetStorageApi;
