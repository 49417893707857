import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useCreateFolder = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const onCreateFolder = async ({
    folderName,
    parentFolderId = "",
    onSuccessCallback,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.post("drive/create-folder", {
        folderName,
        parentFolderId,
      });
      if (res?.status === 201) {
        setData(res?.data?.data);
        onSuccessCallback?.();
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      console.log({err})
      onErrorCallback?.(err?.response?.data?.message || err?.message)
      setError(err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, onCreateFolder };
};

export default useCreateFolder;
