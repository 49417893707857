import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { v1 as uuidv1 } from "uuid";

import useUpdateFileInfo from "./useUpdateFIleInfo";
import { axiosInstance } from "../interceptors/axiosInterceptors";
import { UserContext } from "../context/users/users";
import {
  FILE_UPLOAD_AND_PROCESSING_URL,
  GENERAL_FILE_UPLOAD_FAILURE_ERROR,
} from "../constants/const";

const useUploadFile = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();

  const uniqueFileId = uuidv1();

  const { onUpdateFileInfo } = useUpdateFileInfo();

  const {
    userData: {
      data: { userId },
    },
  } = useContext(UserContext);

  const uploadFile = async ({
    file,
    parentFolderId,
    on1stSuccessCallback,
    on2ndSuccessCallback,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData?.append("file", file);

      const firstApiCall = await axiosInstance?.post("/drive/file", {
        fileId: uniqueFileId,
        userId: userId,
        fileName: file?.name,
        parentFolderId: parentFolderId,
      });

      if (firstApiCall?.status === 200) {
        on1stSuccessCallback?.();
      }

      const url = process.env.REACT_APP_PYTHON_BASE_URL
        ? `${process.env.REACT_APP_PYTHON_BASE_URL}/process_audio_route1`
        : FILE_UPLOAD_AND_PROCESSING_URL;

      const secApiResult = await axiosInstance?.post(url, formData, {
        headers: {
          parentFolderId: parentFolderId,
          fileId: uniqueFileId,
        },
      });

      if (secApiResult?.status === 200) {
        setData(secApiResult?.data?.data);
        on2ndSuccessCallback?.("File has been processed successfully!");
        return;
      }
      setError(secApiResult?.data?.message);
    } catch (err) {
      setError(err?.message);
      onErrorCallback?.(GENERAL_FILE_UPLOAD_FAILURE_ERROR || err?.message);
      onUpdateFileInfo({
        fileId: uniqueFileId,
        data: {
          isError: true,
        },
        onSuccessCallback: () => {
          onErrorCallback?.();
        },
        onErrorCallback: () => {
          toast?.error("Unable to remove the file", {
            hideProgressBar: true,
            className: "error-toast",
          });
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { uploadFile, data, isLoading, error };
};

export default useUploadFile;
