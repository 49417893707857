import React from "react";
import PropTypes from "prop-types";

import Spinner from "../Assets/images/loading.gif";

const Loader = ({ small , customClassName}) => {
  return (
    <div
      className={`${
        small ? "p-3" : "min-h-[50vh]"
      } w-full flex items-center justify-center ${customClassName}`}
    >
      <img
        src={Spinner}
        alt="loading-gif"
        className={`${small ? "w-[34px]" : "w-[100px]"}`}
      />
    </div>
  );
};

Loader.propTypes = {
  small: PropTypes.bool,
  customClassName: PropTypes.string,
};

export default Loader;
