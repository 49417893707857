import React, { useContext } from "react";
import PropTypes from "prop-types";

import BreadCrumValue from "./BreadCrumValue";
import { FolderContext } from "../context/folder/folder";
import { UserContext } from "../context/users/users";

const Breadcrumbs = ({ getFolderData }) => {
  const { folderData: foldersInfo, setFolderData: setFolderInfo } =
    useContext(FolderContext);
  const { userData } = useContext(UserContext);
  const { stack } = foldersInfo;

  const onBreadCrumClick = ({ index, id }) => {
    const updatedStack = stack?.slice(0, index + 1);
    getFolderData({
      folderId: id,
      onSuccessFullCallback: () => {
        setFolderInfo((prev) => {
          return {
            ...prev,
            stack: updatedStack,
          };
        });
      },
    });
  };
  const isOnRoot = stack?.length === 1;
  const isLengthMoreThan3 = stack?.length > 3;

  return (
    <div className="flex items-center gap-[12px]">
      <button
        onClick={
          isOnRoot
            ? () => {}
            : () =>
                onBreadCrumClick({
                  index: 0,
                  id: userData?.data?.folderId,
                })
        }
        className={`text-[#667085] text-[14px] font-[400] ${
          isOnRoot ? "cursor-default" : ""
        }`}
      >
        {" "}
        Home
      </button>
      {stack?.map((folder, index) => {
        if (index === 0) return;
        if (isLengthMoreThan3 && index === stack?.length - 2) {
          return (
            <>
              <BreadCrumValue
                key={folder?.id}
                {...{ onBreadCrumClick, stack }}
                index={index - 1}
                folderId="dooted-menu"
                folderName="...."
                isNonClickable
              />
              <BreadCrumValue
                key={folder?.id + folder?.name}
                {...{ index, onBreadCrumClick, stack }}
                folderId={folder?.id}
                folderName={folder?.name}
              />
            </>
          );
        } else if (isLengthMoreThan3 && index !== stack?.length - 1) {
          return;
        }
        return (
          <BreadCrumValue
            key={folder?.id}
            {...{ index, onBreadCrumClick, stack }}
            folderId={folder?.id}
            folderName={folder?.name}
          />
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  getFolderData: PropTypes.func,
};

export default Breadcrumbs;
