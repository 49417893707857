import React from "react";
import PropTypes from "prop-types";

import Spinner from "../Assets/images/loading.gif";

const CreatePopup = ({
  folderName,
  setFolderName,
  onCreate,
  onCancel,
  areBtnsDisabled,
}) => {
  return (
    <form className="overlay shadow-[4_10px_25px_0px_rgba(#00000005)]">
      <div className="popup w-[595px]">
        <div>
          <h2 className="text-[#101828] text-[18px] font-[600] leading-[24px]">
            Create New Folder
          </h2>
        </div>
        <div className="border-b-[1px] border-[#0000001F] pt-[24px]"></div>
        <div className="w-[100%] pt-[24px]">
          <div className="w-[100%]">
            <h4 className="pb-[12px] text-[#667085] text-[14px] font-[500]">
              Folder Name
            </h4>
            <input
              disabled={areBtnsDisabled}
              maxLength={60}
              value={folderName}
              onChange={(e) => setFolderName?.(e?.target?.value)}
              type="text"
              placeholder="Enter Folder Name"
              className="py-[18px] px-[14px] border border-[#D0D5DD] w-[100%] rounded-[8px] placeholder-[#98A2B3] text-[#101828] text-[16px] font-[400] bg-[#F7F9FB] outline-none"
            />
          </div>
          <div className="flex justify-end w-[100%] pt-[8px]">
            <p className="text-[12px] font-[500] text-[#98A2B3]">
              {folderName?.length}/60
            </p>
          </div>
        </div>
        <div className="w-[100%] flex justify-end pt-[24px] gap-[16px]">
          <button
            type="button"
            disabled={areBtnsDisabled}
            onClick={onCancel}
            className="border border-[#D0D5DD] py-[8px] px-[24px] rounded-[8px] cursor-pointer font-[500] text-[#101828]"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={(e) => {
              e?.preventDefault();
              onCreate();
            }}
            disabled={areBtnsDisabled || folderName?.trim()?.length === 0}
            className="text-[white] bg-[#155EEF] md:text-[16px] text-[14px] font-[500] py-[8px] px-[24px] rounded-[8px] cursor-pointer min-w-[96px] text-center"
          >
            {areBtnsDisabled ? (
              <div className="px-2">
                <img
                  src={Spinner}
                  alt="Spinner-gif"
                  className="w-[24px] mx-auto"
                />
              </div>
            ) : (
              "Create"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

CreatePopup.propTypes = {
  folderName: PropTypes.string,
  setFolderName: PropTypes.func,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  areBtnsDisabled: PropTypes.bool,
};

export default CreatePopup;
