import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ShareFolder = ({ onShare, onCancel, link, isFile }) => {
  const submitForm = (event) => {
    event?.preventDefault();
    if (event?.key === "Enter") {
      onShare();
    }
  };

  useEffect(() => {
    document?.addEventListener("keypress", submitForm);

    return () => {
      document?.removeEventListener("keypress", submitForm);
    };
  }, []);

  return (
    <form className="overlay shadow-[4_10px_25px_0px_rgba(#00000005)]">
      <div className="popup w-[595px]">
        <div>
          <h2 className="text-[#101828] text-[18px] font-[600] leading-[24px]">
            Share {isFile ? "File" : "Folder"}
          </h2>
        </div>
        <div className="border-b-[1px] border-[#0000001F] pt-[24px]"></div>

        <div className="w-[100%] pt-[24px]">
          <div className="w-[100%]">
            <input
              value={link}
              disabled
              type="url"
              placeholder="https://wwwVoisirra.fileshare/"
              className="py-[18px] px-[14px] border border-[#D0D5DD] w-[100%] rounded-[8px] placeholder-[#98A2B3] text-[#101828] text-[16px] font-[400] bg-[#F7F9FB] outline-none"
            />
          </div>
        </div>
        <div className="w-[100%] flex justify-end pt-[24px] gap-[16px]">
          <button
            type="button"
            onClick={onCancel}
            className="border border-[#D0D5DD] py-[8px] px-[24px] rounded-[8px] cursor-pointer md:text-[16px] text-[14px] font-[500] text-[#101828]"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={(e) => {
              e?.preventDefault();
              onShare();
            }}
            className="text-[white] min-w-[99px] bg-[#155EEF] font-[500] py-[8px] px-[13px] rounded-[8px] cursor-pointer md:text-[16px] text-[14px]"
          >
            Copy Link
          </button>
        </div>
      </div>
    </form>
  );
};

ShareFolder.propTypes = {
  onShare: PropTypes.func,
  onCancel: PropTypes.func,
  link: PropTypes.string,
  isFile: PropTypes.bool,
};

export default ShareFolder;
