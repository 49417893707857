import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserContextProvider from "./context/users/users";
import FolderContextProvider from "./context/folder/folder";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <UserContextProvider>
      <FolderContextProvider>
        <App />
      </FolderContextProvider>
    </UserContextProvider>
  </GoogleOAuthProvider>
);
reportWebVitals();
