import React from "react";

import PropTypes from "prop-types";

const Button = ({
  children,
  className,
  img,
  onClick,
  disabled = false,
  isCircular,
}) => {
  return (
    <button
      {...{ onClick, disabled }}
      className={`
      font-[500]  
      p-[16px] 
      gap-[4px]  
      disabled:opacity-70
      disabled:cursor-not-allowed 
      cursor-pointer 
      relative 
      flex 
      justify-center 
      items-center 
      border-[1px] 
      border-light-gray 
      ${isCircular ? "rounded-3xl" : "rounded-[8px]"} 
      w-full ${className} `}
    >
      {children}{" "}
      {img && (
        <img
          src={img}
          alt="button-img"
          className="absolute left-[12px] top-[50%] transform -translate-y-1/2"
        />
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  img: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isCircular: PropTypes.bool,
};


export default Button;
