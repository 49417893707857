import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { UserContext } from "../users/users";
import PropTypes from "prop-types";
import { STACK_KEY_NAME } from "../../constants/const";

export const FolderContext = createContext();

const FolderContextProvider = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [folderData, setFolderData] = useState({
    stack: [],
  });
  const initialLoadRef = useRef(false);

  useEffect(() => {
    const storedStackData = localStorage?.getItem(STACK_KEY_NAME);
    let parseData = JSON.parse(storedStackData);
    parseData = parseData?.filter((obj) => {
      return Object.keys(obj)?.length > 0;
    });

    if (parseData?.length) {
      setFolderData({
        stack: parseData,
      });
      return;
    }


    const updatedStack = [
      {
        id: userData?.data?.folderId,
      },
    ];

    setFolderData({
      stack: updatedStack,
    });

    const stackToStore = JSON?.stringify(updatedStack);
    localStorage?.setItem(STACK_KEY_NAME, stackToStore);
  }, [userData]);

  useEffect(() => {
    if (initialLoadRef?.current) {
      const stackToStore = JSON?.stringify(folderData?.stack);
      localStorage?.setItem(STACK_KEY_NAME, stackToStore);
    }
    initialLoadRef.current = true;
  }, [folderData?.stack?.length]);

  const folderContextValues = useMemo(
    () => ({ folderData, setFolderData }),
    [folderData?.stack?.length, setFolderData, folderData, folderData?.stack]
  );

  return (
    <FolderContext.Provider value={folderContextValues}>
      {children}
    </FolderContext.Provider>
  );
};

FolderContextProvider.propTypes = {
  children: PropTypes.node,
};

export default FolderContextProvider;
