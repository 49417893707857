import React from "react";
import PropTypes from "prop-types";

import { Delete, Share, Rename } from "../Assets/Iconset";

const MenuDropdown = ({ onRename, onDelete, onShare }) => {
  return (
    <div className="w-[151px] rounded-[8px] border border-[#F2F4F7]  bg-[white]">
      <button
        to=""
        onClick={onRename}
        className="flex  gap-[8px] items-center  py-[10px] px-[14px] w-[100$]"
      >
        <Rename className="w-[18px] h-[18px]" />
        <p className="text-[14px] font-[500] text-[#667085] leading-[24px]">
          Rename
        </p>
      </button>
      <button
        onClick={onShare}
        className="flex  gap-[8px] items-center py-[10px] px-[14px] border-y-[1px] border-[#F2F4F7] w-[100%]"
      >
        <Share className="w-[18px] h-[18px]" />
        <p className="text-[14px] font-[500] text-[#667085] leading-[24px]">
          {" "}
          Share
        </p>
      </button>
      <button
        onClick={onDelete}
        className="flex  gap-[8px] items-center py-[10px] px-[14px]"
      >
        <Delete className="w-[18px] h-[18px]" />
        <p className="text-[14px] font-[500] text-[#D92D20] leading-[24px]">
          Delete
        </p>
      </button>
    </div>
  );
};

MenuDropdown.propTypes = {
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
  onShare: PropTypes.func,
};

export default MenuDropdown;
