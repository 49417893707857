import { useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";

const useDeleteFileFromDB = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState();

  const deleteFileDB = async ({
    fileId,
    onSuccessCallback,
    onErrorCallback,
  }) => {
    setIsLoading(true);
    try {
      const res = await axiosInstance?.delete(`/drive/db/file/${fileId}`);
      setData(res?.data);
      onSuccessCallback?.(res?.data);
    } catch (err) {
      onErrorCallback?.(err?.response?.data?.message || err?.message);
      setError(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteFileDB, data, isLoading, error };
};

export default useDeleteFileFromDB;
