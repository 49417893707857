import React from "react";
import PropTypes from "prop-types";

import next from "../../src/Assets/images/next.svg";
import { showAtMaxChar } from "../utils/util";

const BreadCrumValue = ({
  index,
  folderId,
  folderName,
  isNonClickable,
  stack,
  onBreadCrumClick,
}) => {
    
  return (
    <React.Fragment>
      <img src={next} alt="next-arrow" />
      <button
        onClick={
          isNonClickable || stack?.length === index + 1
            ? () => {}
            : () => onBreadCrumClick({ index, id: folderId })
        }
        className={`text-[14px] font-[400] ${
          stack?.length === index + 1 ? "text-[#155EEF] cursor-default" : "text-[#667085] cursor-pointer"
        }`}
      >
        {showAtMaxChar(showAtMaxChar(folderName, 15), 15)}
      </button>
    </React.Fragment>
  );
};

BreadCrumValue.propTypes = {
  index: PropTypes.number,
  folderId: PropTypes.string,
  folderName: PropTypes.string,
  isNonClickable: PropTypes.bool,
  stack: PropTypes.array,
  onBreadCrumClick: PropTypes.func,
};

export default BreadCrumValue;
