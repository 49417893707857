import { useContext, useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import { UserContext } from "../context/users/users";
import { FolderContext } from "../context/folder/folder";

let pusherInstance = null;
let channel = null;

const useFileUploadNotification = ({ callBack }) => {
  const [listOfUploadedFiles, setListOfUploadedFiles] = useState([]);
  const {
    userData: {
      data: { userId },
    },
  } = useContext(UserContext);
  const {
    folderData: { stack },
  } = useContext(FolderContext);

  const stackRef = useRef({ stack });
  useEffect(() => {
    stackRef.current.stack = stack;
  }, [stack?.length]);

  useEffect(() => {
    // Initialize Pusher instance if not already initialized
    if (!pusherInstance) {
      pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
      });
    }

    // Subscribe to the channel if not already subscribed
    if (!channel) {
      const userChannelName = `file-upload-channel-${userId}`;
      channel = pusherInstance.subscribe(userChannelName);

      // Bind to the event
      channel.bind("file-uploaded", (data) => {
        if (data?.isSuccess) {
          if (data?.isFileUploaded) {
            setListOfUploadedFiles((prev) => [...prev, data?.fileId]);
            toast.success("File uploaded successfully", {
              hideProgressBar: true,
              className: "success-toast",
            });
            return;
          } else {
            toast.success("File processed successfully", {
              hideProgressBar: true,
              className: "success-toast",
            });
          }
        } else {
          toast.error(data.message, {
            hideProgressBar: true,
            className: "error-toast",
          });
        }
        callBack?.(stackRef?.current?.stack?.at(-1)?.id);
      });
    }

    // Cleanup function to unbind event handlers
    return () => {
      if (channel) {
        channel?.unbind("file-uploaded");
        pusherInstance?.unsubscribe("file-upload-channel");
        channel = null;
      }
    };
  }, []);

  return { listOfUploadedFiles };
};

export default useFileUploadNotification;
