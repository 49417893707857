import React, { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import DeleteFolder from "../popups/DeleteFolder";
import Dotmenu from "../Assets/images/dotmenu.svg";
import DownloadMenu from "../dropdownmenus/DownloadMenu";
import RenamePopup from "../popups/RenamePopup";
import ShareFolder from "../popups/ShareFolder";

import useDeleteFolderApi from "../hooks/useDeleteFolderApi";
import useOutsideClick from "../hooks/useOutsideClick";
import useRenameFolderApi from "../hooks/useRenameFolderApi";
import useShareLink from "../hooks/useShareLink";
import Cross from "../Assets/images/cross.png";
import loadingAnimation from "../Assets/images/loading.gif";
import record from "../../src/Assets/images/recordings.png";
import {
  convertGoogleDriveUrlToDirectLink,
  getElapsedTimeInMinutes,
} from "../utils/util";
import { VALID_TIME_LIMIT } from "../constants/const";
import useDeleteFileFromDB from "../hooks/useDeleteFileFromDB";

const Recordings = ({
  name,
  id,
  loadInitialData,
  listOfUploadedFiles,
  onAudioFileClick,
  isProcessing,
  isError,
  createdAt,
}) => {
  const elapsedTime = getElapsedTimeInMinutes(createdAt) || 0;
  const isVideoProblematic = isError || elapsedTime > VALID_TIME_LIMIT;
  const fileIsProcessingNotCorrupted = isProcessing && !isVideoProblematic;

  // Modal toggle states
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareLinkModalOpen, setIsShareLinkModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Modal input states
  const [newFileName, setNewFileName] = useState(name || "");

  // Custom api hooks
  const { onCreateShareLink, data: shareableLink } = useShareLink();
  const { renameFolder, isLoading: isRenamingFolder } = useRenameFolderApi();
  const { deleteFolder, isLoading: isDeletingFolder } = useDeleteFolderApi();
  const { ref, shouldNotConsiderRef } = useOutsideClick({
    callback: () => {
      setIsMenuOpen(false);
    },
  });
  const { deleteFileDB, isLoading: isDeletingFileFromDB } =
    useDeleteFileFromDB();

  // functions
  const createLinkHanlder = () => {
    onCreateShareLink({
      fileId: id,
      onSuccessCallback: () => {
        setIsShareLinkModalOpen(true);
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  const deleteFileHanlder = () => {
    if (isVideoProblematic) {
      deleteFileDB({
        fileId: id,
        onSuccessCallback: () => {
          loadInitialData?.();
          toast?.dark("File deleted successfully", {
            hideProgressBar: true,
          });
        },
        onErrorCallback: (error) => {
          toast?.error(error, {
            hideProgressBar: true,
            className: "error-toast",
          });
        },
      });
      return;
    }

    deleteFolder({
      folderId: id,
      onSuccessCallback: () => {
        loadInitialData?.();
        toast?.dark("File deleted successfully", {
          hideProgressBar: true,
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  const renameFileHandler = () => {
    renameFolder({
      folderId: id,
      newFolderName: newFileName,
      onSuccessCallback: () => {
        loadInitialData?.();
        toast?.success("File renamed successfully", {
          hideProgressBar: true,
          className: "success-toast",
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  const downloadFileHanlder = async () => {
    onCreateShareLink({
      fileId: id,
      onSuccessCallback: (filePublicLink) => {
        const directUrl = convertGoogleDriveUrlToDirectLink(filePublicLink);
        const link = document.createElement("a");
        link.href = directUrl;
        link.click();
      },
      onErrorCallback: () => {
        toast?.error("Unable to download file", {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };

  const onCopyPublicLink = () => {
    navigator.clipboard.writeText(shareableLink);
    toast.success("Link copied successfully", {
      hideProgressBar: true,
      className: "success-toast",
    });
    setIsShareLinkModalOpen(false);
  };

  const onCancelRename = () => {
    setIsRenameModalOpen(false);
    setNewFileName(name);
  };

  return (
    <div className={`${fileIsProcessingNotCorrupted ? "opacity-60" : ""}`}>
      <div className="relative bg-[white] p-[16px] rounded-[12px] max-w-[384px]  min-h-[60px] w-full">
        <div className="flex justify-between items-center">
          <button
            className={`flex gap-[12px] items-center flex-1 w-[90%] ${
              fileIsProcessingNotCorrupted ? "cursor-default" : ""
            }`}
            onClick={fileIsProcessingNotCorrupted ? () => {} : onAudioFileClick}
          >
            <img src={record} alt="" className="w-[32px] h-[42px]" />
            <p className="overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[90%]">
              {name}
            </p>
          </button>
          <div ref={shouldNotConsiderRef}>
            <button
              onClick={
                fileIsProcessingNotCorrupted
                  ? () => {}
                  : () => setIsMenuOpen((prev) => !prev)
              }
            >
              <img src={Dotmenu} alt="" className="w-[24px] h-[24px]" />
            </button>
          </div>
        </div>
        {isProcessing && !isVideoProblematic && (
          <div className="flex justify-between pt-[8px]">
            <p>
              {listOfUploadedFiles?.includes(id) ? "Processing" : "Uploading"}
            </p>
            <img src={loadingAnimation} alt="" className="w-[27px] h-[27px]" />
          </div>
        )}
        {isVideoProblematic && (
          <div className="p-1 flex justify-start items-center gap-1">
            <img src={Cross} alt="cross" className="w-[30px]" />
            <p className="text-[16px]">Processing failed, upload again!</p>
          </div>
        )}
        {isRenameModalOpen && (
          <RenamePopup
            value={newFileName}
            onNameChange={(e) => setNewFileName(e?.target?.value)}
            onSubmit={renameFileHandler}
            onCancel={onCancelRename}
            areBtnsDisabled={isRenamingFolder}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteFolder
            isFile
            areBtnsDisabled={isDeletingFolder || isDeletingFileFromDB}
            onCancel={() => setIsDeleteModalOpen(false)}
            onDelete={deleteFileHanlder}
          />
        )}
        {isMenuOpen && (
          <div ref={ref} className="absolute right-[20px] top-[50px] z-20">
            <DownloadMenu
              onShare={createLinkHanlder}
              onDelete={() => setIsDeleteModalOpen(true)}
              onDownload={downloadFileHanlder}
              onRename={() => setIsRenameModalOpen(true)}
              isDownloadNotRequired={isVideoProblematic}
              isRenameNotRequired={isVideoProblematic}
              isShareNotRequired={isVideoProblematic}
            />
          </div>
        )}
        {isShareLinkModalOpen && (
          <ShareFolder
            isFile
            onCancel={() => setIsShareLinkModalOpen(false)}
            onShare={onCopyPublicLink}
            link={shareableLink}
          />
        )}
      </div>
    </div>
  );
};

Recordings.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  loadInitialData: PropTypes.func,
  listOfUploadedFiles: PropTypes.array,
  onAudioFileClick: PropTypes.func,
  isProcessing: PropTypes.bool,
  isError: PropTypes.bool,
  createdAt: PropTypes.string,
};

export default Recordings;
