import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";

import Loader from "./Loader";
import { UserContext } from "../context/users/users";
import useGetStorageApi from "../hooks/useGetStorageApi";
import { fakDelay } from "../utils/util";
import { STACK_KEY_NAME } from "../constants/const";

const ProtectedAndPublicRoute = ({ component: Component, isPublic }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { setUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const { getStorage } = useGetStorageApi();

  useEffect(() => {
    const verifyingUser = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          await getStorage();
          setUserData({
            data: decodedToken,
          });
          setIsAuthenticated(true);
        } catch (error) {
          setIsAuthenticated(false);
          localStorage.removeItem(STACK_KEY_NAME);
        }
      } else {
        localStorage.removeItem(STACK_KEY_NAME);
        setIsAuthenticated(false);
      }

      await fakDelay(3000);
      setIsLoading(false);
    };

    verifyingUser();
  }, []);

  if (isLoading) {
    return (
      <div className="flex-1 min-h-[50vh] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  if (isPublic) {
    return <>{!isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" />}</>;
  }

  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>;
};

ProtectedAndPublicRoute.propTypes = {
  component: PropTypes.node,
  isPublic: PropTypes.bool,
};

export default ProtectedAndPublicRoute;
