import { ToastContainer } from "react-toastify";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CloseIcon } from "../src/Assets/Iconset";
import Authentication from "./auth/Authentication";
import AudioPreview from "./pages/AudioPreview";
import Dashboard from "./pages/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import ProtectedAndPublicRoute from "./components/ProtectedAndPublicRoute";

import "react-toastify/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={
          <span className="min-h-[10px] cursor-pointer">
            <CloseIcon />
          </span>
        }
      />
      <Routes>
        <Route exact path="/" element={<ProtectedAndPublicRoute isPublic />}>
          <Route exact path="" element={<Authentication />} />
        </Route>
        <Route exact path="/" element={<ProtectedAndPublicRoute />}>
          <Route exact path="dashboard" element={<Dashboard />} />
          <Route path="audio" element={<AudioPreview />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
