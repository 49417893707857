export const ALL_SCOPES =
  "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata";

export const MAX_FILE_SIZE = 510 * 1024 * 1024;

export const VALID_FILE_TYPES = ["audio/mpeg", "audio/x-m4a", "audio/wav"];

export const LIMIT = 30;

export const GENERAL_LOGIN_FAILED_ERROR =
  "Google Drive Access Denied: Sign-in Failed.";

export const GENERAL_FILE_UPLOAD_FAILURE_ERROR =
  "Unable to upload file to google drive!";

export const SEARCH_PARAMS = "q";

export const VALID_TIME_LIMIT = 10;

export const STACK_KEY_NAME = "location-stack";

export const FILE_UPLOAD_AND_PROCESSING_URL =
  "https://my-space-arp-python-dev.hestalabs.com/process_audio_route1";
