import React from "react";
import empty from "../../src/Assets/images/empty.svg";
import PropTypes from "prop-types";

const CreateFolder = ({ isNoSearchResultFound, isOnRoot }) => {
  const getStatusText = () => {
    return isOnRoot
      ? "Create a new folder to organize your audio files"
      : "Upload audios or create a folder";
  };

  return (
    <div className="container">
      <div className="flex flex-col h-[calc(100vh-223px)] justify-center items-center gap-[10px] m-auto">
        <img src={empty} alt="" className="md:w-[243px] w-[120px] md:h-[240px] h-[119px]" />
        <p className="text-[#667085] text-[16px] font-[500]">
          {isNoSearchResultFound ? "No file/folder found" : getStatusText()}
        </p>
      </div>
    </div>
  );
};

CreateFolder.propTypes = {
  isNoSearchResultFound: PropTypes.bool,
  isOnRoot: PropTypes.bool,
};

export default CreateFolder;
