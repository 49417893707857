import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

import AudioPlayer from "../components/AudioPlayer";
import CreateFolder from "../components/CreateFolder";
import FolderCreated from "../components/FolderCreated";
import Loader from "../components/Loader";
import Searchmenu from "../components/Searchmenu";
import Recordings from "../components/Recordings";
import SecondaryHeader from "../components/SecondaryHeader";

import useDebounce from "../hooks/useDebounce";
import useFileUploadNotification from "../hooks/useFileUploadNotification";
import useGetFolderDataApi from "../hooks/useGetFolderDataApi";
import useGetStorageApi from "../hooks/useGetStorageApi";
import useSearch from "../hooks/useSearch";
import { FolderContext } from "../context/folder/folder";
import { UserContext } from "../context/users/users";
import { byteToGigaByte } from "../utils/util";
import { LIMIT, SEARCH_PARAMS } from "../constants/const";

const Dashboard = () => {
  // context
  const {
    userData: { data },
  } = useContext(UserContext);

  const { folderData: foldersInfo, setFolderData: setFolderInfo } =
    useContext(FolderContext);
  const { stack } = foldersInfo;

  // react hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // states
  const [selectedAudioFileToPlay, setSelectedAudioFileToPlay] = useState({
    id: "",
    name: "",
  });
  const [userSearch, setUserSearch] = useState(() => {
    return searchParams?.get(SEARCH_PARAMS) || "";
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [isFirstPageCall, setIsFirstPageCall] = useState(true);

  // custom hooks
  const { data: userStorageData, getStorage } = useGetStorageApi();
  const {
    getFolderData,
    data: paginatedData,
    isLoading,
  } = useGetFolderDataApi();
  const {
    onSearch,
    data: paginatedSearchReult,
    isLoading: isSearching,
  } = useSearch();

  const totalStorage = byteToGigaByte(+userStorageData?.limit);
  const consumedStorage = byteToGigaByte(+userStorageData?.usage);

  const isDashboard = stack?.length <= 1;
  const folderData = paginatedData?.data;
  const totalFolderData = paginatedData?.total;

  const searchResult = paginatedSearchReult?.data;
  const totalSearchData = paginatedSearchReult?.total;

  const loadInitialData = (folderIdToFetchData) => {
    getStorage();

    if (userSearch) {
      onSearch({
        folderId: data?.folderId,
        query: userSearch,
        page: 1,
        onErrorCallBack: (error) => {
          toast?.error(error, {
            hideProgressBar: true,
            className: "error-toast",
          });
        },
      });
      setCurrentPage(1);
      return;
    }

    const folderIdToGetCurrentPageData =
      folderIdToFetchData || stack?.at(-1)?.id;
    getFolderData({
      folderId: folderIdToGetCurrentPageData,
      page: 1,
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
    setCurrentPage(1);
  };
  const { listOfUploadedFiles } = useFileUploadNotification({
    callBack: loadInitialData,
  });

  const { debouncedCallback } = useDebounce((event) => {
    const searchedQuery = event?.target?.value;
    if (searchedQuery) {
      setSearchParams((prev) => {
        prev?.set(SEARCH_PARAMS, searchedQuery);
        return prev;
      });

      onSearch({
        folderId: stack?.at(-1)?.id,
        query: searchedQuery,
        page: 1,
        onErrorCallBack: (error) => {
          toast?.error(error, {
            hideProgressBar: true,
            className: "error-toast",
          });
        },
      });
    } else {
      setSearchParams((prev) => {
        prev?.delete(SEARCH_PARAMS);
        return prev;
      });
      getFolderData({
        folderId: stack?.at(-1)?.id,
        page: 1,
        onErrorCallback: (error) => {
          toast?.error(error, {
            hideProgressBar: true,
            className: "error-toast",
          });
        },
      });
    }
    setSelectedAudioFileToPlay({ id: "", name: "" });
    setCurrentPage(1);
  });

  // functions
  const onFolderClick = (clickedFolderInfo) => {
    setUserSearch("");
    setSearchParams((prev) => {
      prev?.delete(SEARCH_PARAMS);
      return prev;
    });
    getFolderData({
      folderId: clickedFolderInfo?.id,
      onSuccessFullCallback: () => {
        setFolderInfo((prev) => {
          return {
            ...prev,
            stack: [...stack, clickedFolderInfo],
          };
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };
  const fetchNextSetOfData = () => {
    setIsFirstPageCall(false);
    if (userSearch) {
      onSearch({
        isResetData: false,
        folderId: stack?.at(-1)?.id,
        query: userSearch,
        page: currentPage + 1,
        onSuccessCallback: () => {
          setCurrentPage((prev) => prev + 1);
          setIsFirstPageCall(true);
        },
        onErrorCallBack: () => {
          setIsFirstPageCall(true);
        },
      });
      return;
    }
    getFolderData({
      isResetData: false,
      folderId: stack?.at(-1)?.id || data?.folderId,
      page: currentPage + 1,
      onSuccessFullCallback: () => {
        setCurrentPage((prev) => prev + 1);
        setIsFirstPageCall(true);
      },
      onErrorCallback: () => {
        setIsFirstPageCall(true);
      },
    });
  };

  const hasMoreDataToLoad = userSearch
    ? totalSearchData > currentPage * LIMIT
    : totalFolderData > currentPage * LIMIT;
  const currentFolderId = stack?.at(-1)?.id;
  const foldersDataToRender = !userSearch
    ? folderData
    : searchResult?.filter((item) => item?.id !== currentFolderId);

  const showLoader = isFirstPageCall && (isSearching || isLoading);

  useEffect(() => {
    loadInitialData(stack?.at(-1)?.id || data?.folderId);
  }, []);

  return (
    <div
      className={`relative flex-1 flex flex-col ${
        selectedAudioFileToPlay?.id ? "max-h-[100vh]" : ""
      }`}
    >
      {selectedAudioFileToPlay?.id && (
        <AudioPlayer
          onBack={() => setSelectedAudioFileToPlay({ id: "", name: "" })}
          id={selectedAudioFileToPlay?.id}
          name={selectedAudioFileToPlay?.name}
        />
      )}
      <Searchmenu
        name={data?.name}
        picture={data?.picture}
        email={data?.email}
        {...{
          totalStorage,
          consumedStorage,
          debouncedCallback,
          userSearch,
          setUserSearch,
        }}
      />
      <div
        className={`bg-[#F7F9FD] flex-1 flex flex-col ${
          selectedAudioFileToPlay?.id ? "overflow-hidden" : ""
        }`}
      >
        <SecondaryHeader
          isDashboard={isDashboard}
          areActionButtonsDisabled={showLoader}
          {...{
            getFolderData,
            loadInitialData,
            stack,
            setFolderInfo,
            setIsFirstPageCall,
          }}
        />
        {showLoader && <Loader />}
        {!foldersDataToRender?.length && !showLoader && (
          <CreateFolder
            isOnRoot={isDashboard}
            isNoSearchResultFound={Boolean(
              userSearch && foldersDataToRender?.length === 0
            )}
          />
        )}
        {!!foldersDataToRender?.length && !showLoader && (
          <InfiniteScroll
            style={{ paddingBottom: 240 }}
            className="container py-[24px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 gap-y-6 h-[100%]"
            dataLength={foldersDataToRender.length}
            next={fetchNextSetOfData}
            hasMore={hasMoreDataToLoad}
            loader={<Loader small customClassName="col-span-full" />}
          >
            {foldersDataToRender?.map((folder) => {
              if (!folder?.isFolder) {
                return (
                  <Recordings
                    isProcessing={folder?.isProcessing}
                    isError={folder?.isError}
                    key={folder?.id}
                    name={folder?.name}
                    id={folder?.id || folder?.fileId}
                    createdAt={folder?.createdAt}
                    {...{ loadInitialData, listOfUploadedFiles }}
                    onAudioFileClick={() =>
                      setSelectedAudioFileToPlay({
                        id: folder?.id,
                        name: folder?.name,
                      })
                    }
                  />
                );
              }
              return (
                <FolderCreated
                  {...{ loadInitialData }}
                  key={folder?.id}
                  name={folder?.name}
                  folderId={folder?.id}
                  onClick={() =>
                    onFolderClick({
                      id: folder?.id,
                      name: folder?.name,
                      parentFolderId: folder?.parents?.[0],
                    })
                  }
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
