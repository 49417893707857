import React from "react";
import PropTypes from "prop-types";
import { STACK_KEY_NAME } from "../constants/const";

const LogOut = React.forwardRef(({ onLogout, name, email, picture }, ref) => {
  const logoutHandler = () => {
    localStorage?.removeItem(STACK_KEY_NAME);
    onLogout?.();
  };

  return (
    <div
      ref={ref}
      className="lg:min-w-[240px] min-w-[196px] border border-[#F2F4F7]  rounded-[8px] absolute right-[0px] top-[38px] bg-[white] z-[999] shadow-[0_12px_16px_-4px_rgba(#10182814)]"
    >
      <div className="flex gap-[12px] px-[16px] py-[12px] items-center">
        <div className=" w-[40px] h-[40px] text-center justify-center flex items-center rounded-full">
          <img src={picture} alt="profileimage" className="rounded-full" />
        </div>
        <div>
          <p className="text-[#344054] text-[14px] font-[500] text-left max-w-[200px] whitespace-nowrap overflow-hidden overflow-ellipsis">
            {name}
          </p>
          <p className="max-w-[200px] whitespace-nowrap overflow-hidden overflow-ellipsis text-[14px] text-[#667085] font-[400]">
            {email}
          </p>
        </div>
      </div>
      <div className="w-[100%] h-[2px] bg-[#F2F4F7]"></div>
      <div className="w-full text-left">
        <button
          onClick={logoutHandler}
          className="text-[#344054] text-[14px] font-[400] w-full text-start px-[16px] py-[12px]"
        >
          Log out
        </button>
      </div>
    </div>
  );
});
LogOut.propTypes = {
  onLogout: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
};
export default LogOut;
