import React from "react";
import PropTypes from "prop-types";

const CancelBtn = ({ children, className, onClick , disabled}) => {
  
  return (
    <button
      {...{ onClick, disabled }}
      className={`border border-[#D0D5DD] py-[8px] px-[24px] rounded-[8px] text-[16px] font-[500] text-[#101828] ${className}`}
    >
      {children}
    </button>
  );
};

CancelBtn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CancelBtn.defaultProps = {
  className: "",
};

export default CancelBtn;
