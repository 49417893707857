import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { fakDelay } from "../utils/util";
import { ALL_SCOPES, GENERAL_LOGIN_FAILED_ERROR } from "../constants/const";

const useLogin = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  

  const login = useGoogleLogin({
    scope: ALL_SCOPES,
    flow: "auth-code",
    onSuccess: async (response) => {
      try {
        setIsLoading(true);
        const res = await axios?.post(`${BASE_URL}/users/login`, {
          code: response?.code,
        });
        localStorage?.setItem("token", res?.data?.jwtToken);
        const decodedData = jwtDecode(res?.data?.jwtToken);

        if (decodedData?.newRootFolderCreationMessage) {
          toast?.success(decodedData?.newRootFolderCreationMessage, {
            hideProgressBar: true,
            className: "success-toast",
          });

          await fakDelay(3000);
          window.location.reload();
          return;
        }

        window.location.reload();
      } catch (err) {
        console.log({ err });
        toast.error(GENERAL_LOGIN_FAILED_ERROR, {
          hideProgressBar: true,
          className: "error-toast",
        });
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setError(error);
    },
  });

  return { login, error, isLoading };
};

export default useLogin;
