import React, { useState } from "react";
import PropTypes from "prop-types";

import LogOut from "../dropdownmenus/LogOut";
import useOutsideClick from "../hooks/useOutsideClick";
import { useSearchParams } from "react-router-dom";
import logo from "../Assets/images/voisirra.png";
import SearchCross from "../Assets/images/search-cross.svg";
import { SearchIcon } from "../Assets/Iconset";
import { SEARCH_PARAMS, STACK_KEY_NAME } from "../constants/const";

const Searchmenu = ({
  name,
  picture,
  email,
  consumedStorage,
  debouncedCallback,
  totalStorage,
  userSearch,
  setUserSearch,
}) => {
  const [, setSearchParams] = useSearchParams();
  const [isLogOutVisible, setIsLogOutVisible] = useState(false);
  const { ref, shouldNotConsiderRef } = useOutsideClick({
    callback: () => {
      setIsLogOutVisible(false);
    },
  });

  const toggleLogOut = () => {
    setIsLogOutVisible((prevState) => !prevState);
  };
  const onLogoutHandler = () => {
    localStorage.removeItem("token");
    setIsLogOutVisible(false);
    window?.location?.reload();
  };
  const onRedirectToHome = () => {
    setSearchParams((prev) => {
      prev.delete(SEARCH_PARAMS);
      return prev;
    });
    localStorage?.removeItem(STACK_KEY_NAME);
    window?.location?.reload();
  };
  const consumedPercentage = Number(
    ((+consumedStorage / +totalStorage) * 100)?.toFixed()
  );

  return (
    <div className="container py-[24px]">
      <div className="flex justify-between md:py-[24px] pt-[18px] pb-[65px] relative">
        <div className="flex gap-[24px]  items-center">
          <button onClick={onRedirectToHome}>
            <img
              src={logo}
              alt=""
              className="md:max-w-[133px] max-w-[82px]  md:h-[24px] h-[14px] md:static relative"
            />
          </button>
          <div className="flex border border-[#D0D5DD] h-[42px]  items-center md:w-[327px] justify-between py-[12px] px-[16px] rounded-[858px] bg-[#F7F9FD] md:static absolute left-0 right-0 bottom-[18px]">
            <input
              onChange={(e) => {
                setUserSearch(e?.target?.value);
                debouncedCallback(e);
              }}
              value={userSearch}
              type="text"
              placeholder="Search in Voisirra"
              className="font-[400] text-[14px] text-[#98A2B3] leading-[16px] w-[100%] h-[100%] outline-none border-[none] pr-[8px] bg-[#F7F9FD]"
            />
            {!userSearch && <SearchIcon className="w-[11px] h-[11px]" />}
            {!!userSearch && (
              <button
                onClick={() => {
                  debouncedCallback({ target: { value: "" } });
                  setUserSearch("");
                }}
              >
                <img
                  src={SearchCross}
                  alt="search-cross"
                  className="w-[14px] h-[14px]"
                />
              </button>
            )}
          </div>
        </div>
        <div className="w-[310px] flex gap-[16px] items-center">
          <div className="w-[100%]">
            <p className="w-[100%] text-end lg:pb-[16px] pb-[0] text-[#667085] text-[14px] leading-[16px] font-[500]">
              {+consumedStorage || 0} Gb / {+totalStorage || 0} Gb
            </p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 overflow-hidden lg:flex hidden">
              <div
                className={`bg-blue-600 h-2.5 rounded-full`}
                style={{ width: `${consumedPercentage}%` }}
              ></div>
            </div>
          </div>
          <button
            onClick={toggleLogOut}
            className="relative w-[40px] h-[36px] text-center justify-center flex items-center rounded-full cursor-default"
          >
            <img
              src={picture}
              alt="profileImage"
              className="rounded-full cursor-pointer"
              ref={shouldNotConsiderRef}
            />
            {isLogOutVisible && (
              <LogOut
                {...{ name, email, picture, ref }}
                onLogout={onLogoutHandler}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

Searchmenu.propTypes = {
  name: PropTypes.string,
  picture: PropTypes.string,
  email: PropTypes.string,
  consumedStorage: PropTypes.string,
  debouncedCallback: PropTypes.func,
  totalStorage: PropTypes.string,
  userSearch: PropTypes.string,
  setUserSearch: PropTypes.func,
};

export default Searchmenu;
