import React from "react";
import Searchmenu from "../components/Searchmenu";
import { BackArrow } from "../Assets/Iconset";

const AudioPreview = () => {

  return (
    <div>
      <Searchmenu />
      <div className="bg-[#242424] py-[16px] h-[68px]">
        <div className="container">
          <div className="flex items-center gap-[12px]">
            <BackArrow className="cursor-pointer" />
            <p className="text-[#FFFFFF] text-[18px] font-[600]">
              Recording003
            </p>
          </div>
        </div>
      </div>
      <div className="h-[calc(100vh-158px)] bg-[#000000]  flex ">
        <div className="w-[661px] h-[52px] flex justify-center items-center m-auto md:px-[0px] px-[30px]">
          <audio
            controls
            className="w-[100%] "
            controlsList="nodownload noplaybackrate"
          >
            <source src="" type="audio/mpeg" className="bg-[#4D4D4D" />
            <track
              src="path/to/captions.vtt"
              kind="captions"
              srcLang="en"
              label="English"
            />
          </audio>
        </div>
      </div>
    </div>
  );
};

export default AudioPreview;
