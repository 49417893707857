import React, { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

import Breadcrumbs from "./Breadcrumbs";
import Button from "./Button";
import CreatePopup from "../popups/CreatePopup";
import Upload from "../uploadsPopups/Upload";
import UploadsIcon from "../../src/Assets/images/upload.png";
import useCreateFolder from "../hooks/useCreateFolder";
import blueplus from "../../src/Assets/images/addblue.svg";
import { Backarrow, Plusfolder } from "../Assets/Iconset";

const SecondaryHeader = ({
  isDashboard,
  getFolderData,
  loadInitialData,
  stack,
  setFolderInfo,
  areActionButtonsDisabled,
}) => {
  const { onCreateFolder, isLoading: isFolderGettingCreated } =
    useCreateFolder();
  const [folderName, setFolderName] = useState("");
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isUploadAudioModalOpen, setIsUploadAudioModalOpen] = useState(false);
  const currentFolderName = stack?.at(-1)?.name;

  const clickNewFolderHandler = () => {
    onCreateFolder({
      folderName,
      parentFolderId: stack?.at(-1)?.id,
      onSuccessCallback: () => {
        setIsFolderModalOpen(false);
        setFolderName("");
        loadInitialData();
        toast?.success("New folder has been created", {
          hideProgressBar: true,
          className: "success-toast",
        });
      },
      onErrorCallback: (error) => {
        toast?.error(error, {
          hideProgressBar: true,
          className: "error-toast",
        });
      },
    });
  };
  const onCancel = () => {
    setIsFolderModalOpen(false);
    setFolderName("");
  };
  const onClickBack = () => {
    const updatedStack = stack?.slice(0, stack?.length - 1);
    getFolderData({
      folderId: updatedStack?.at(-1)?.id,
      page: 1,
    });
    setFolderInfo((prev) => {
      return {
        ...prev,
        stack: updatedStack,
      };
    });
  };

  return (
    <div className="container py-[24px]">
      {isFolderModalOpen && (
        <CreatePopup
          {...{ folderName, setFolderName, onCancel }}
          onCreate={clickNewFolderHandler}
          areBtnsDisabled={isFolderGettingCreated}
        />
      )}
      {isDashboard && (
        <div className="flex lg:flex-row flex-col  gap-[20px]  lg:items-center justify-between py-[24px]">
          <div className="flex flex-col md:gap-[12px] gap-[8px]">
            <h2 className="md:text-[24px] text-[18px] lg:text-[32px] font-[600] text-[#101828] leading-[36px]">
              Welcome to Your Dashboard
            </h2>
            <Breadcrumbs {...{ getFolderData }} />
          </div>
          <div className="flex items-center justify-end">
            <button
              disabled={areActionButtonsDisabled}
              onClick={() => setIsFolderModalOpen(true)}
              className="bg-[#155EEF] hover:bg-[#0343C4] flex gap-[6px] items-center md:h-[48px] h-[40px] md:w-[184px] w-[160px] justify-center text-[white] font-[500] rounded-[8px] md:text-[16px] text-[14px] "
            >
              <Plusfolder />
              Create Folder
            </button>
          </div>
        </div>
      )}
      {!isDashboard && (
        <div className="flex justify-between py-[24px] lg:flex-row flex-col gap-[20px] lg:items-center items-start">
          <div className="flex gap-[12px] flex-col">
            <div className="flex gap-[12px] items-center">
              <button onClick={onClickBack}>
                <Backarrow />
              </button>
              <h2 className="md:text-[32px] text-[18px] font-[600] text-[#101828] leading-[36px] overflow-ellipsis whitespace-nowrap overflow-hidden max-w-[40vw]">
                {currentFolderName}
              </h2>
            </div>
            <Breadcrumbs {...{ getFolderData }} />
          </div>
          <div className="md:w-auto w-full flex md:gap-[24px] gap-[12px] items-center md: justify-end">
            <button
              disabled={areActionButtonsDisabled}
              onClick={() => setIsFolderModalOpen(true)}
              className="font-[500] md:text-[16px] text-[14px] text-[#155EEF] flex items-center gap-[4px] md:p-[16px] p-[8px] min-w-max cursor-pointer"
            >
              <img src={blueplus} alt="Plus" className="mr-[4px]" />
              <p>Create Folder</p>
            </button>
            <Button
              disabled={areActionButtonsDisabled}
              onClick={() => setIsUploadAudioModalOpen(true)}
              className="bg-[#155EEF] items-center justify-center text-[white] md:!w-[184px] w-[160px] md:h-[48px] h-[40px] max-w-[max-content]"
            >
              <img
                src={UploadsIcon}
                alt="upload-icon"
                className="w-[18px] h-[18px] mr-[4px]"
              />
              <p>Upload Audio</p>
            </Button>
          </div>
        </div>
      )}
      {isUploadAudioModalOpen && (
        <Upload
          onCancel={() => setIsUploadAudioModalOpen(false)}
          {...{ loadInitialData }}
        />
      )}
    </div>
  );
};

SecondaryHeader.propTypes = {
  isDashboard: PropTypes.bool,
  getFolderData: PropTypes.func,
  loadInitialData: PropTypes.func,
  stack: PropTypes.array,
  setFolderInfo: PropTypes.func,
  areActionButtonsDisabled: PropTypes.bool,
};

export default SecondaryHeader;
