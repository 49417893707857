import React from 'react'

const NotFoundPage = () => {

  return (
    <div className='w-full flex justify-center items-center flex-1'>
        <p className='text-4xl font-semibold'>Oops The page you are looking for does not exist!</p>
    </div>
  )
}

export default NotFoundPage