import React from "react";
import PropTypes from "prop-types";

import { Delete, Share, Rename, Download } from "../Assets/Iconset";

const DownloadMenu = ({
  onShare,
  onDownload,
  onDelete,
  onRename,
  isRenameNotRequired,
  isDeleteNotRequired,
  isShareNotRequired,
  isDownloadNotRequired,
}) => {
  return (
    <div className="w-[151px] rounded-[8px] border border-[#F2F4F7] bg-white">
      {!isRenameNotRequired && (
        <button
          onClick={onRename}
          className="flex gap-[8px] items-center py-[10px] px-[14px] w-full"
        >
          <Rename className="w-[18px] h-[18px]" />
          <p className="text-[14px] font-[500] text-[#667085] leading-[24px]">
            Rename
          </p>
        </button>
      )}
      {!isDownloadNotRequired && (
        <button
          onClick={onDownload}
          className="flex gap-[8px] items-center py-[10px] px-[14px] border-y-[1px] border-[#F2F4F7] w-full"
        >
          <Download className="w-[18px] h-[18px]" />
          <p className="text-[14px] font-[500] text-[#667085] leading-[24px]">
            Download
          </p>
        </button>
      )}
      {!isShareNotRequired && (
        <button
          onClick={onShare}
          className="flex gap-[8px] items-center py-[10px] px-[14px] border-y-[1px] border-[#F2F4F7] w-full"
        >
          <Share className="w-[18px] h-[18px]" />
          <p className="text-[14px] font-[500] text-[#667085] leading-[24px]">
            Share
          </p>
        </button>
      )}
      {!isDeleteNotRequired && (
        <button
          onClick={onDelete}
          className="flex gap-[8px] items-center py-[10px] px-[14px]"
        >
          <Delete className="w-[18px] h-[18px]" />
          <p className="text-[14px] font-[500] text-[#D92D20] leading-[24px]">
            Delete
          </p>
        </button>
      )}
    </div>
  );
};

DownloadMenu.propTypes = {
  onShare: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  isRenameNotRequired: PropTypes.bool,
  isDeleteNotRequired: PropTypes.bool,
  isShareNotRequired: PropTypes.bool,
  isDownloadNotRequired: PropTypes.bool,
};

export default DownloadMenu;
