import { useContext, useState } from "react";
import { axiosInstance } from "../interceptors/axiosInterceptors";
import { UserContext } from "../context/users/users";
import { LIMIT } from "../constants/const";
import { mergeData } from "../utils/util";

const useSearch = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState();
  const { userData } = useContext(UserContext);
  const { folderId: rootFolderId } = userData?.data || {};

  const onSearch = async ({
    isResetData = true,
    folderId,
    query,
    page = 1,
    limit = LIMIT,
    onSuccessCallback,
    onErrorCallBack,
  }) => {
    setIsLoading(true);
    try {
      const paginationQuery =
        page && limit ? `&page=${page}&limit=${limit}` : "";

      const res = await axiosInstance.get(
        `drive/search/${folderId || rootFolderId}?${paginationQuery}`,
        {
          headers: {
            q: query,
          },
        }
      );
      if (res?.status === 200) {
        mergeData(setData, res, isResetData);
        onSuccessCallback?.();
        return;
      }
      setError(res?.data?.message);
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
      onErrorCallBack?.(err?.response?.data?.message || err?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { onSearch, data, isLoading, error };
};

export default useSearch;
