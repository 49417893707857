import { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const initialState = {
  data: null,
};

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(initialState);

  const userContextValue = useMemo(
    () => ({ userData, setUserData }),
    [userData, setUserData]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserContextProvider;
